import dynamic from 'next/dynamic';
import { LazyContainer } from '@/types/containers/lazyContainers';

const LazyContainers: LazyContainer = {
    BasePage: dynamic(() => import('./BasePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    StandardPage: dynamic(() => import('./StandardPage')),
    SearchPage: dynamic(() => import('./SearchPage')),
    ListOverviewPage: dynamic(() => import('./ListOverviewPage')),
    ListItemPage: dynamic(() => import('./ListItemPage')),
};

export default LazyContainers;
